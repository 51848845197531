import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import imagebg from "../../static/img/uploads/404.png";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";

const NotFoundPage = () => {
  const { meta, social } = useSiteMetadata();
  return (
    <Layout darkMenu={true} backgroundColour={`primary`}>
      <Seo
        title={
          "Whoops" +
          meta.sitePageTitleSep +
          "404 Error" +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={meta.siteUrl + "/404/"}
        description={meta.siteDescription}
        image={meta.siteImage}
        twitterTitle={"Whoops" + meta.sitePageTitleSep + "404 Error"}
        facebookTitle={"Whoops" + meta.sitePageTitleSep + "404 Error"}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />
      <header id="hero" className="hero hero_404">
        <div className="position-relative container-fluid">
          <div className="row contained-xl justify-content-center">
            <div className="text-center text-md-left pb-4 pb-lg-5 mb-lg-5 col-lg-4 col-md-5 offset-md-1 offset-lg-0 text-left align-self-center">
              <h1 className="display-2 mb-4 text-white">Oops-a-daisy!</h1>
              <p className="lead mb-4 text-white">
                It looks like this page doesn't exist?
                <br />
                Maybe it was an old link? We'll look into it for you, but in the
                meantime...
              </p>
              <Link to="/" className="btn btn-white">
                Let's take you home
              </Link>
            </div>
            <div className="col-7 col-md-6 col-lg-4 align-self-center text-left h-100">
              <div className="position-relative">
                <img
                  src={imagebg}
                  className="img-fluid h-100"
                  alt="background"
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </Layout>
  );
};

export default NotFoundPage;
